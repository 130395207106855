var Util = 
{
    /**
     * 
     * @param {string} string 
     * @param {string} pad 
     * @param {int} length 
     * @returns 
     */
    stringPadLeft(string, pad, length) 
    {
        return (new Array(length + 1).join(pad) + string).slice(-length);
    },

    /**
     * Converts 123 -> '02:03'
     * 
     * @param {float} totalSeconds 
     * @returns string
     */
    secondsToTimeString(totalSeconds)
    {
        var minutes = Math.floor(totalSeconds / 60);
        var seconds = Math.floor(totalSeconds - minutes * 60);

        return this.stringPadLeft(minutes, '0', 2) + ':' + this.stringPadLeft(seconds, '0', 2);
    },

    /**
     * Clamps a value to min/max
     * 
     * @param {float} value 
     * @param {float} min 
     * @param {float} max 
     * @returns float
     */
    clamp(value, min, max)
    {
        if (value > max)
        {
            return max;
        }

        if (value < min)
        {
            return min;
        }

        return value;
    },

    /**
     * 
     * @param {*} a 
     * @param {*} b 
     * @returns 
     */
    max(a, b)
    {
        return a > b ? a : b;
    }
}
/* exported CookieDialog */

/**
 * Denials are saved in  session storage, so that the next 
 * time the visitor comes by, they are being asked again.
 * 
 * Accepts are saved in local storage, so that they are
 * never asked again.
 * 
 * Does not use the word "banner", since adblockers might block it
 */
function CookieDialog()
{
    return {
        isOpen: false,
        cookieDecision: null,
        hasExecutedCookieOnlyCode: false,

        /**
         * Initialises the dialog
         */
        init()
        {
            this.loadCookieDecision();

            window.addEventListener('show-cookie-dialog', this.open.bind(this));

            switch (this.cookieDecision)
            {
                // User has accepted cookies
                case true:
                {
                    this.runCookieOnlyCode();
                    break;
                }
    
                // User was never asked
                case null: 
                {
                    this.open();
                    break;
                }
            }
        }, 

        /**
         * Returns true if cookies are allowed.
         * Returns false if cookies are disallowed.
         * Returns null if cookies are undecided yet.
         */
        loadCookieDecision()
        {
            var localStorageEntry = localStorage.getItem('bs-accepted-cookies');
            var sessionStorageEntry = sessionStorage.getItem('bs-denied-cookies');

            if (localStorageEntry === 'true')
            {
                this.cookieDecision = true;
            }
            else 
            {
                if (sessionStorageEntry === 'true')
                {
                    this.cookieDecision = false;
                }
                else 
                {
                    this.cookieDecision = null;
                }
            } 
        },

        /**
         * Executes JS that should only run if cookies are allowed
         */
        runCookieOnlyCode()
        {
            // Dont do it twice
            if (this.hasExecutedCookieOnlyCode)
            {
                return;
            }

            this.hasExecutedCookieOnlyCode = true;
            
            window.dataLayer = window.dataLayer || [];
            function gtag(){window.dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'G-1E11XPFL3W');

            var scriptElement = document.createElement('script');
            scriptElement.setAttribute('src', 'https://www.googletagmanager.com/gtag/js?id=G-1E11XPFL3W');
            document.body.appendChild(scriptElement);
        },

        /**
         * Closes the dialog
         */
        close()
        {
            this.isOpen = false;
        },

        /**
         * Opens the dialog
         */
        open()
        {
            this.isOpen = true;
        },

        /**
         * Accepts cookies and closes dialog
         */
        accept()
        {
            sessionStorage.removeItem('bs-denied-cookies');
            localStorage.setItem('bs-accepted-cookies', true);
            
            this.cookieDecision = true;
            this.runCookieOnlyCode();
            this.close();
        },

        /**
         * Denies cookies and closes dialog
         */
        deny()
        {
            sessionStorage.setItem('bs-denied-cookies', true);
            localStorage.removeItem('bs-accepted-cookies');

            this.cookieDecision = false;
            this.close();
        }
    }
}
/* exported AudioSample */

function AudioSample(_id)
{
    return {
        id: _id,
        isPlaying: false,
        position: 0,
        circularProgressCircumference: 17 * 2 * Math.PI,
        circularProgress: 0,

        init()
        {
            this.updateCircularProgress(0);
            this.$el.addEventListener('play-sample', this.onPlay.bind(this));
            this.$el.addEventListener('pause-sample', this.onPause.bind(this));
            this.$el.addEventListener('tick-sample', this.onTick.bind(this));
        },              

        /**
         * Updates the circular progress stroke dash offset
         */
        updateCircularProgress(progress)
        {
            this.circularProgress = Util.max(0, this.circularProgressCircumference - progress * this.circularProgressCircumference);
        },

        /**
         * This sample was paused
         */
        onPause()
        {
            this.isPlaying = false;
        },

        /**
         * This sample started playing
         */
        onPlay()
        {
            this.isPlaying = true;
        },

        /**
         * This sample is playing/ticking
         */
        onTick(event)
        {
            this.updateCircularProgress(event.detail);
        }
    };
}
/* global AudioSamples */
/* exported AudioPlayer */

function AudioPlayer()
{
    return {
        isPlaying: false,
        isMuted: false,
        volume: 0.8,
        volumePercent: 80,
        volumeBeforeMute: 0.8,
        volumeProgressElement: null,
        volumeProgressBoundingBox: null,
        playlist: [],
        currentSampleId: null,
        currentSampleElement: null,
        sampleData: null,
        cssRoot: null,
        audio: null,
        durationText: '',
        positionText: '',
        trackElement: null,
        trackBoundingBox: null,
        trackProgressWidth: 0,
        isDraggingTrack: false,
        lastDraggedSeekTime: 0,
        eventListeners: {},
        hasMp3: false,

        init()
        {
            // Grad the CSS root
            this.cssRoot = document.querySelector(':root');

            this.trackElement = document.getElementById('audio-player-track');
            this.trackBoundingBox = this.trackElement.getBoundingClientRect();

            this.volumeProgressElement = document.getElementById('audio-player-volume');
            this.volumeProgressBoundingBox = this.volumeProgressElement.getBoundingClientRect();

            // Create event handles, so that we can remove the event listeners again
            this.eventListeners = 
            {
                onVolumeGlobalMouseMove: this.onVolumeGlobalMouseMove.bind(this),
                onVolumeGlobalMouseUp: this.onVolumeGlobalMouseUp.bind(this),
                onTrackGlobalMouseMove: this.onTrackGlobalMouseMove.bind(this),
                onTrackGlobalMouseUp: this.onTrackGlobalMouseUp.bind(this),
                onAudioEnded: this.onAudioEnded.bind(this),
                onAudioTimeUpdate: this.onAudioTimeUpdate.bind(this),
                onToggleSample: this.onToggleSample.bind(this),
                onWindowResize: this.onWindowResize.bind(this)
            };

            // Create an audio instance
            this.audio = new Audio();
            this.audio.volume = this.volumeBeforeMute;
            this.audio.addEventListener('ended', this.eventListeners.onAudioEnded);
            this.audio.addEventListener('timeupdate', this.eventListeners.onAudioTimeUpdate);    

            // bind() is keeping the this scope
            window.addEventListener('toggle-sample', this.eventListeners.onToggleSample);
            window.addEventListener('resize', this.eventListeners.onWindowResize);

            // Create a playlist, containing all Ids
            // and select the first one
            for (var sampleId in AudioSamples.samples)
            {
                this.playlist.push(sampleId);
            }

            this.setActiveSample(AudioSamples.activeSampleId, false);

            // Try to load volume from session storage
            var savedVolume = sessionStorage.getItem('bs-volume');

            if (savedVolume)
            {
                this.changeVolume(parseFloat(savedVolume));
            }
        },

        /**
         * Window was resized
         */
        onWindowResize()
        {
            var ratio = this.audio.duration > 0 ? this.audio.currentTime / this.audio.duration : 0;

            this.trackBoundingBox = this.trackElement.getBoundingClientRect();
            this.trackProgressWidth = Math.floor(ratio * this.trackBoundingBox.width);
            this.volumeProgressBoundingBox = this.volumeProgressElement.getBoundingClientRect();
        },

        /**
         * The audio playback has ended
         */
        onAudioEnded()
        {
            this.isPlaying = false;

            // Let the sample know that playing is paused
            if (this.currentSampleElement)
            {
                this.currentSampleElement.dispatchEvent(new CustomEvent('pause-sample'));
            }
        },

        /**
         * Audio is playing/ticking
         */
        onAudioTimeUpdate()
        {
            var ratio = this.audio.currentTime / this.sampleData.duration;

            // Do not update the time and track when draggin the track
            if (!this.isDraggingTrack)
            {
                // Nice thing is, Alpine only touches DOM if value changes. That 
                // Throttles stuff quite a lot. Love it.
                this.positionText = Util.secondsToTimeString(this.audio.currentTime);

                // Round to nearest pixel to avoid -webkit-mask-image to become blurry
                // when using percentages
                this.trackProgressWidth = Math.floor(ratio * this.trackBoundingBox.width);
            }

            // Update circular progress on sample
            if (this.currentSampleElement)
            {
                this.currentSampleElement.dispatchEvent(new CustomEvent('tick-sample', {detail: ratio}));
            }
        },

        /**
         * User has clicked the play/pause button on the sample grid
         */
        onToggleSample(event)
        {
            var sampleId = event.detail;

            if (sampleId == this.currentSampleId)
            {
                this.togglePlay();
            }
            else 
            {
                this.pause();
                this.setActiveSample(sampleId, true);
                this.play();
            }
        },

        /**
         * Changes the active sample to another one
         */
        setActiveSample(sampleId, loadMp3)
        {
            this.sampleData = AudioSamples.samples[sampleId];
            this.currentSampleId = sampleId;
            this.currentSampleElement = document.getElementById('sample-' + sampleId);

            // Change waveform SVG 
            this.cssRoot.style.setProperty('--waveform-md', 'url(/static/audio/' + sampleId + '/waveform-768.svg)');
            this.cssRoot.style.setProperty('--waveform-lg', 'url(/static/audio/' + sampleId + '/waveform-1024.svg)');
            this.cssRoot.style.setProperty('--waveform-xl', 'url(/static/audio/' + sampleId + '/waveform-1280.svg)');
            this.cssRoot.style.setProperty('--waveform-2xl', 'url(/static/audio/' + sampleId + '/waveform-1600.svg)');

            // Download mp3, but only if wanted.
            // We do not want to download the mp3 on page load
            // to avoid large initial network payload
            if (loadMp3)
            {
                this.audio.src = '/static/audio/' + sampleId + '/audio.mp3'; 
                this.hasMp3 = true;
            }
            else 
            {
                this.hasMp3 = false;
            }

            // Update position/duration
            this.durationText = Util.secondsToTimeString(this.sampleData.duration);
            this.positionText = Util.secondsToTimeString(0);
        },

        /**
         * Mute/Unmute
         */
         toggleMute()
        {
            if (this.isMuted)
            {
                this.changeVolume(this.volumeBeforeMute);
            }
            else 
            {
                this.volumeBeforeMute = this.volume;
                this.changeVolume(0);
            }
        },

        /**
         * Changes the volume
         */
        changeVolume(volume)
        {
            this.isMuted = volume == 0;
            this.volume = volume;
            this.volumePercent = volume.toFixed(2) * 100;
            this.audio.muted = this.isMuted;
            this.audio.volume = volume;

            sessionStorage.setItem('bs-volume', volume);
        },

        /**
         * Starts the playback
         */
        play()
        {
            // If the audio is not yet loaded, start streaming it
            if (!this.hasMp3)
            {
                this.audio.src = '/static/audio/' + this.currentSampleId + '/audio.mp3'; 
                this.hasMp3 = true;
            }
            
            // Remember we are playing
            this.isPlaying = true;

            // Let the sample know it is playing
            if (this.currentSampleElement)
            {
                this.currentSampleElement.dispatchEvent(new CustomEvent('play-sample'));
            }

            // Play the audio
            this.audio.play();
        },

        /**
         * Pauses the playback
         */
        pause()
        {
            // Remember we are not playing anymore
            this.isPlaying = false;

            // Let the sample know that playing is paused
            if (this.currentSampleElement)
            {
                this.currentSampleElement.dispatchEvent(new CustomEvent('pause-sample'));
            }
            
            // Pause the audio
            this.audio.pause();
        },

        /**
         * Goes to next playlist entry
         */
        next()
        {
            this.walkPlaylist(1);
        },

        /**
         * Goes to previous playlist entry
         */
        previous()
        {
            this.walkPlaylist(-1);
        },

        /**
         * Changes to next/previous playlist entry
         */
        walkPlaylist(direction)
        {
            var position = this.playlist.indexOf(this.currentSampleId);
            
            position += direction;

            if (position >= this.playlist.length)
            {
                position = 0;
            }
            else 
            {
                if (position < 0)
                {
                    position = this.playlist.length - 1;
                }
            }

            var newSampleId = this.playlist[position];

            // If paused, change sample.
            // If playing, change sample and continue playing.
            if (!this.isPlaying)
            {
                this.setActiveSample(newSampleId, true);
            }
            else 
            {
                this.pause();
                this.setActiveSample(newSampleId, true);
                this.play();
            }
        },

        /**
         * Play/Pause
         */
        togglePlay()
        {
            if (this.isPlaying)
            {
                this.pause();
            }
            else 
            {
                this.play();
            }
        },

        /**
         * Open share dialog 
         */
        share()
        {
            if (typeof navigator.share !== 'undefined')
            {
                var shareData = 
                {
                    title: this.sampleData['title'],
                    text: 'Hör dir das an 🎧👀',
                    url: 'https://www.bastian.audio/' + this.sampleData['urlKey']
                };

                if (navigator.canShare(shareData))
                {
                    navigator.share(shareData);
                }
            }
        },

        /**
         * User has pressed mouse 1 on the the waveform
         * 
         * @param {Event} event 
         */
        onTrackMouseDown(event)
        {
            // Only do things on mouse 1
            if (event.button == 0)
            {
                var mousePositionOnWaveform = Util.clamp(event.clientX - this.trackBoundingBox.left, 0, this.trackBoundingBox.width);
                var ratio = mousePositionOnWaveform / this.trackBoundingBox.width;
                var seconds = ratio * this.sampleData.duration;
        
                // Update the current time
                this.positionText = Util.secondsToTimeString(seconds);
        
                // Round to nearest pixel to avoid -webkit-mask-image to become blurry
                // when using percentages
                this.trackProgressWidth = Math.floor(ratio * this.trackBoundingBox.width);

                // Remember we are dragging, so that ticking does not update
                // time and waveform
                this.isDraggingTrack = true;

                // Remember where we were seeking to
                this.lastDraggedSeekTime = seconds;

                // Listen to mouse movement, so that we can update progress bar
                window.addEventListener('mousemove', this.eventListeners.onTrackGlobalMouseMove);
                window.addEventListener('mouseup', this.eventListeners.onTrackGlobalMouseUp);
            }
        },

        /**
         * User is dragging waveform slider
         * 
         * @param {Event} event 
         */
        onTrackGlobalMouseMove(event)
        {
            var mousePositionOnWaveform = Util.clamp(event.clientX - this.trackBoundingBox.left, 0, this.trackBoundingBox.width);
            var ratio = mousePositionOnWaveform / this.trackBoundingBox.width;
            var seconds = ratio * this.sampleData.duration;

            // Update the current time
            this.positionText = Util.secondsToTimeString(seconds);

            // Round to nearest pixel to avoid -webkit-mask-image to become blurry
            // when using percentages
            this.trackProgressWidth = Math.floor(ratio * this.trackBoundingBox.width);

            // Remember where we were seeking to
            this.lastDraggedSeekTime = seconds;

            event.preventDefault();
            event.stopPropagation();
        },

        /**
         * User has stopped dragging waveform slider
         * 
         * @param {Event} event 
         */
        onTrackGlobalMouseUp(event)
        {
            // Unbind
            window.removeEventListener('mouseup', this.eventListeners.onTrackGlobalMouseUp);
            window.removeEventListener('mousemove', this.eventListeners.onTrackGlobalMouseMove);

            // Stop dragging
            this.isDraggingTrack = false;

            // Seek
            this.audio.currentTime = this.lastDraggedSeekTime;

            event.preventDefault();
            event.stopPropagation();
        },

        /**
         * User has pressed mouse 1 on the the volume progress
         * 
         * @param {Event} event 
         */
        onVolumeProgressMouseDown(event)
        {
            // Only do things on mouse 1
            if (event.button == 0)
            {
                // Calculate volume and progress bar
                var mousePositionOnProgressBar = Util.clamp(event.clientX - this.volumeProgressBoundingBox.left, 0, this.volumeProgressBoundingBox.width);
                var volume = mousePositionOnProgressBar / this.volumeProgressBoundingBox.width

                this.changeVolume(volume);

                // Listen to mouse movement, so that we can update progress bar
                window.addEventListener('mousemove', this.eventListeners.onVolumeGlobalMouseMove);
                window.addEventListener('mouseup', this.eventListeners.onVolumeGlobalMouseUp);
            }
        },
        
        /**
         * User is dragging volume slider
         * 
         * @param {Event} event 
         */
        onVolumeGlobalMouseMove(event)
        {
            var mousePositionOnProgressBar = Util.clamp(event.clientX - this.volumeProgressBoundingBox.left, 0, this.volumeProgressBoundingBox.width);
            var volume = mousePositionOnProgressBar / this.volumeProgressBoundingBox.width

            this.changeVolume(volume);

            event.preventDefault();
            event.stopPropagation();
        },

        /**
         * User has stopped dragging volume slider
         * 
         * @param {Event} event 
         */
        onVolumeGlobalMouseUp(event)
        {
            // Unbind
            window.removeEventListener('mouseup', this.eventListeners.onVolumeGlobalMouseUp);
            window.removeEventListener('mousemove', this.eventListeners.onVolumeGlobalMouseMove);

            event.preventDefault();
            event.stopPropagation();
        }
    };
}